import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VSwitch} from "vuetify/lib";

const switchComponent = VSwitch as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiSwitch',
	extends: switchComponent,
	props: {
		hideDetails: {
			type: String,
			default: 'auto',
		},
	},
});
