
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import ChapterHeadline from "@/components/content/chapter/ChapterHeadline.vue";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {ChapterHeadline}
	})
	export default class ChapterContent extends Vue {

		@Prop({type: [String, Object], required: true})
		public title!: string | TranslateResult;

		@Prop({type: [String, Object], default: null})
		public subTitle!: string | TranslateResult | null;

		@Prop({type: [String, Object], default: null})
		public headline!: string | TranslateResult | null;

		@Prop({type: String, default: 'white'})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		@Prop({type: Boolean, default: false})
		public flat!: boolean;

		@Prop({type: Boolean, default: false})
		public unframed!: boolean;

		private get contentClasses(): object {
			const ret = {
				'white--text dark': this.dark,
				'rounded elevation-3': !this.flat,
			};
			//@ts-ignore
			ret[this.color] = true;
			return ret;
		}

		private get headlineClasses(): object {
			return {
				'dark': this.dark,
				'stripes-dark': this.dark,
				'bg-lightest stripes mb-6': !this.unframed,
			};
		}

		private get hasSlotActions(): boolean {
			return !!this.$slots.actions || !!this.$scopedSlots['actions'];
		}

	}
