export default class StringHelper {

	private static charsFrom: string = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
	private static charsTo: string = 'aaaaaeeeeiiiioooouuuunc------';

	public static slug(str: string): string {
		str = str
			.replace(/^\s+|\s+$/g, '')
			.toLowerCase();

		for (let i = 0, l = this.charsFrom.length; i < l; i++) {
			str = str.replace(new RegExp(this.charsFrom.charAt(i), 'g'), this.charsTo.charAt(i));
		}

		str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

}
