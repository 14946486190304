
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {TranslateResult} from "vue-i18n";
	import AsiSwitch from "@/components/common/AsiSwitch";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiTextFieldSimple, AsiBtn, AsiSwitch}
	})
	export default class CalculatorRow extends Vue {

		@Prop({type: Number, default: 0})
		public value!: number;

		@Prop({type: Number, default: 0})
		public price!: number;

		@Prop({type: Number, default: 49.9})
		public pricePerTerraByte!: number;

		@Prop({type: [String, Object], required: true})
		public title!: string | TranslateResult;

		@Prop({type: [String, Object], required: true})
		public desc!: string | TranslateResult;

		@Prop({type: Number, default: 0})
		public min!: number;

		@Prop({type: Number, default: null})
		public max!: number;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public descAsHint!: boolean;

		private icons = Icon;
		private valueInternal: number = 0;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private get isBooleanMode(): boolean {
			return this.min === 0 && this.max === 1;
		}

		private get booleanValue(): boolean {
			return this.valueInternal > 0;
		}

		private set booleanValue(value: boolean) {
			this.valueInternal = value ? 1 : 0;
		}

		private get disabledFinal(): boolean {
			return this.disabled || (!this.canDecrease && !this.canIncrease);
		}

		private get canDecrease(): boolean {
			return this.value > this.min;
		}

		private get canIncrease(): boolean {
			return this.max === null || this.value < this.max;
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number): void {
			if (value < this.min) value = this.min;
			if (this.max !== null && value > this.max) value = this.max;
			if (this.valueInternal !== value) this.valueInternal = value;
		}

		@Watch('valueInternal')
		private onValueInternalChanged(value: number): void {
			if (value < this.min) value = this.min;
			if (this.max !== null && value > this.max) value = this.max;
			if (this.value !== value) this.input(value);
		}

		@Emit('input')
		public input(value: number): number {
			return value;
		}

		private decrease(): void {
			if (this.canDecrease) {
				this.input(this.value - 1);
			}
		}

		private increase(): void {
			if (this.canIncrease) {
				this.input(this.value + 1);
			}
		}

	}
