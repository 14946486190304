import Vue, {PluginObject} from "vue";

//const itemService = new AxiosItemService();

const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		//vueInstance.$itemService = itemService;

		Object.defineProperties(vueInstance.prototype, {
			// $itemService: {
			// 	get() {
			// 		return itemService;
			// 	}
			// },
		});
	},
} as PluginObject<any>;

Vue.use(Plugin.install);
export default Plugin;
