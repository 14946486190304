import Vue, {FunctionalComponentOptions, VueConstructor} from 'vue';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/vue-fontawesome';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {PropsDefinition} from 'vue/types/options';

import {
	faAngleDown, faBars, faBookOpen,
	faCalculator,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faClipboardListCheck,
	faDotCircle,
	faEllipsisV,
	faExclamationSquare,
	faFile,
	faFileCheck,
	faFileMagnifyingGlass,
	faFileSignature,
	faHome,
	faInfoSquare, faKey,
	faLongArrowUp,
	faMerge,
	faMinus,
	faMinusSquare,
	faPencil,
	faPlus,
	faSave,
	faSearch,
	faShoppingCart,
	faSpinner,
	faStar,
	faStarHalf,
	faStepBackward,
	faStepForward,
	faTicket,
	faTimes,
	faTrash,
	faUpload,
	faUsersClass,
	faVideo
} from '@fortawesome/pro-solid-svg-icons';
import {faSquare, faStar as faStarEmpty, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {faYoutube} from "@fortawesome/free-brands-svg-icons";

export interface Icons {
	//vuetify
	complete: string;
	cancel: string;
	close: string;
	save: string;
	delete: string;
	add: string;
	remove: string;
	confirm: string;
	clear: string;
	merge: string;
	success: string;
	info: string;
	warning: string;
	error: string;
	prev: string;
	next: string;
	checkboxOn: string;
	checkboxOff: string;
	checkboxIndeterminate: string;
	delimiter: string; // for carousel
	sort: string;
	expand: string;
	menu: string;
	subgroup: string;
	dropdown: string;
	radioOn: string;
	radioOff: string;
	edit: string;
	ratingEmpty: string;
	ratingFull: string;
	ratingHalf: string;
	loading: string;
	first: string;
	last: string;
	unfold: string;
	file: string;
	fileCheck: string;
	preview: string;

	//common icons
	burger: string;
	home: string;
	search: string;
	upload: string;
	video: string;
	youtube: string;
	order: string;
	calculate: string;
	pagination: string;
	demo: string;
	testAccount: string;
	brochure: string;

	//annio icons
	bullet: string;
	taskBoard: string;
	slaTicket: string;
	report: string;

}

const iconMap: { [name: string]: IconDefinition } = {
	//vuetify
	complete: faCheck,
	cancel: faTimes,
	close: faTimes,
	save: faSave,
	delete: faTrash,
	add: faPlus,
	remove: faMinus,
	confirm: faCheck,
	clear: faTimes,
	merge: faMerge,
	success: faCheck,
	info: faInfoSquare,
	warning: faExclamationSquare,
	error: faExclamationSquare,
	prev: faChevronLeft,
	next: faChevronRight,
	checkboxOn: faCheckSquare,
	checkboxOff: faSquare,
	checkboxIndeterminate: faMinusSquare,
	delimiter: faCircle,
	sort: faLongArrowUp,
	expand: faPlus,
	menu: faEllipsisV,
	subgroup: faAngleDown,
	dropdown: faAngleDown,
	radioOn: faDotCircle,
	radioOff: faCircle,
	edit: faPencil,
	ratingEmpty: faStarEmpty,
	ratingFull: faStar,
	ratingHalf: faStarHalf,
	loading: faSpinner,
	first: faStepBackward,
	last: faStepForward,
	unfold: faAngleDown,
	file: faFile,
	fileCheck: faFileCheck,
	preview: faFileMagnifyingGlass,

	//common icons
	burger: faBars,
	home: faHome,
	search: faSearch,
	upload: faUpload,
	video: faVideo,
	youtube: faYoutube,
	order: faShoppingCart,
	calculate: faCalculator,
	pagination: faCircle,
	demo: faUsersClass,
	testAccount: faKey,
	brochure: faBookOpen,

	//annio icons
	bullet: faCheckCircle,
	taskBoard: faClipboardListCheck,
	slaTicket: faTicket,
	report: faFileSignature,
};

export interface VuetifyIconConfig {
	component: FunctionalComponentOptions<FontAwesomeIconProps, PropsDefinition<FontAwesomeIconProps>> & VueConstructor;
	props: { icon: IconProp };
}

Vue.component('font-awesome-icon', FontAwesomeIcon);
const Icon: Icons | { [name: string]: string } = {};
const IconConfig: { [name: string]: VuetifyIconConfig } = {};
Object.keys(iconMap).forEach((k: string) => {
	const def = iconMap[k] as IconDefinition;
	Icon[k] = '$vuetify.icons.' + k;
//@ts-ignore
	library.add(def);

	IconConfig[k] = {
		component: FontAwesomeIcon,
		props: {
			icon: [def.prefix, def.iconName]
		}
	} as VuetifyIconConfig;
});
export default Icon;
export {IconConfig};
