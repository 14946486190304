
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import ITestimonial from "@/models/ITestimonial";
	import {TranslateResult} from "vue-i18n";

	@Component
	export default class Testimonial extends Vue {

		@Prop({type: Object, required: true})
		public testimonial!: ITestimonial;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private get authorStringCombined(): string | TranslateResult {
			return `${this.testimonial.author}, ${this.testimonial.job}, ${this.testimonial.company}`;
		}

	}
