"use strict";

import axios from "axios";
import Vue, {PluginObject} from "vue";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
	baseURL: process.env.VUE_APP_API_BASE_URL || ""
	// timeout: 60 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response;
	},
	function (error) {
		// reject if either status code doesn't match, auth/refresh failed or token-expired header is not present
		return Promise.reject(error);
	}
);

const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		vueInstance.$axios = _axios;

		(window as any).axios = _axios;
		Object.defineProperties(vueInstance.prototype, {
			axios: {
				get() {
					return _axios;
				}
			},
			$axios: {
				get() {
					return _axios;
				}
			},
		});
	}
} as PluginObject<any>;


Vue.use(Plugin.install);
export default Plugin;

