import {Module} from "vuex";
import {State} from "@/store";

export interface UI {
	mobileMenuOpen: boolean,
}

const ui: Module<UI, State> = {
	namespaced: true,
	state: {
		mobileMenuOpen: false,
	},
	mutations: {
		setMobileMenuOpen(state: UI, isOpen: boolean): void {
			state.mobileMenuOpen = isOpen;
		},
	},
};

export default ui;
