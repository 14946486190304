
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import VueI18n from "vue-i18n";
	import ChapterHeadline from "@/components/content/chapter/ChapterHeadline.vue";
	import StringHelper from "@/helpers/StringHelper";
	import ChapterImage from "@/components/content/chapter/ChapterImage.vue";
	import ChapterContent from "@/components/content/chapter/ChapterContent.vue";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {ChapterContent, ChapterImage, ChapterHeadline}
	})
	export default class Chapter extends Vue {

		@Prop({type: [String, Object], required: true})
		public title!: string | TranslateResult;

		@Prop({type: Boolean, default: false})
		public fillScreen!: boolean;

		@Prop({type: Boolean, default: false})
		public filled!: boolean;

		@Prop({type: Boolean, default: false})
		public unpadded!: boolean;

		@Prop({type: Boolean, default: false})
		public reverse!: boolean;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private get titleSlug(): string {
			return StringHelper.slug(this.title.toString());
		}

		private get hasSlotPrepend(): boolean {
			return !!this.$slots.prepend || !!this.$scopedSlots['prepend'];
		}

		private get hasSlotAside(): boolean {
			return !!this.$slots.aside || !!this.$scopedSlots['aside'];
		}

		private get numColumns(): number {
			return this.hasSlotAside ? 2 : 1;
		}

		private get wrapperClasses(): object {
			return {
				'fill-screen': this.fillScreen,
				'grey lighten-5': this.filled,
				'pa-6 pa-lg-12 pa-xl-12': !this.unpadded,
				'with-aside': this.hasSlotAside,
				'mobile': this.isMobile,
			};
		}

		private get contentClasses(): object {
			return {
				'dense': !this.$vuetify.breakpoint.lgAndUp,
				'reverse': this.reverse,
				'mobile': this.isMobile,
			};
		}

		private get contentStyles(): object {
			const numColumns = this.$vuetify.breakpoint.lgAndUp ? this.numColumns : 1;
			return {
				'grid-template-columns': `repeat(${numColumns}, 1fr)`,
			};
		}

	}
