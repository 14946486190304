
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class Logo extends Vue {

		@Prop({type: String, default: 'salmon'})
		public color!: string;

		private animationFinished: boolean = false;

		public mounted(): void {
			setTimeout(() => this.animationFinished = true, 5000);
		}

	}
