import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import {Routes} from "@/helpers/Routes";
import {PositionResult} from "vue-router/types/router";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: Routes.home,
		component: HomeView,
		meta: {
			title: 'pages.home',
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition): PositionResult | null {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0,
			};
		}
	},
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
	//set page title
	let title = i18n.t('application').toString();
	if (to.meta && to.meta.title !== undefined) {
		title = i18n.t(to.meta.title).toString() + ' | ' + title;
	}
	document.title = title;

	next();
});

export default router;
