
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {TranslateResult} from "vue-i18n";

	@Component
	export default class CalculatorGroupHeader extends Vue {

		@Prop({type: [String, Object], required: true})
		public title!: string | TranslateResult;

	}
