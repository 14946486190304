
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Testimonial from "@/components/content/testimonials/Testimonial.vue";
	import Icon from "@/plugins/icons";
	import ITestimonial from "@/models/ITestimonial";

	@Component({
		components: {Testimonial}
	})
	export default class Testimonials extends Vue {

		private icons = Icon;
		private testimonials: ITestimonial[] = [];
		private activeTab: number = 0;
		private interval: any = null;
		private restartTimeout: any = null;

		public created(): void {
			this.testimonials = this.$t('testimonials.entries') as unknown as ITestimonial[];
			this.activeTab = Math.floor(Math.random() * this.testimonials.length);
			this.startInterval();
		}

		private get numTestimonials(): number {
			return this.testimonials.length;
		}

		private startInterval(): void {
			clearTimeout(this.interval);
			clearTimeout(this.restartTimeout);
			this.interval = setInterval(this.showNext, 5000);
		}

		private stopInterval(autoRestart: boolean): void {
			clearInterval(this.interval);
			clearTimeout(this.restartTimeout);
			if (autoRestart) setTimeout(this.startInterval, 60000);
		}

		private showIndex(index: number, stopInterval: boolean = true): void {
			this.activeTab = index;
			if (stopInterval) this.stopInterval(true);
		}

		private showNext(): void {
			this.activeTab = (this.activeTab + 1) % this.testimonials.length;
		}

	}
