
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import VueI18n from "vue-i18n";
	import Icon from "@/plugins/icons";
	import TranslateResult = VueI18n.TranslateResult;

	@Component
	export default class ChapterBullets extends Vue {

		@Prop({type: Array, required: true})
		public bullets!: (string | TranslateResult)[];

		@Prop({type: String, default: Icon.bullet})
		public icon!: string;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		@Prop({type: Boolean, default: true})
		public staggered!: boolean;

		@Prop({type: Number, default: 200})
		public staggerDelay!: number;

		private staggeringStarted: boolean = false;
		private staggeredIndex: number = 0;
		private staggeringInterval: any = null;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private intersected(entries: any[]): void {
			const isIntersecting = entries[0].isIntersecting;
			if (!this.staggered || !isIntersecting || this.staggeringStarted) return;
			this.staggeringStarted = true;
			this.performStaggering();
		}

		private isStaggeredIn(index: number): boolean {
			return this.staggeredIndex >= index;
		}

		private performStaggering(): void {
			setTimeout(() => {
				this.staggeringInterval = setInterval(() => {
					this.staggeredIndex++;
					if (this.staggeredIndex >= this.bullets.length) {
						clearInterval(this.staggeringInterval);
					}
				}, 50);
			}, this.staggerDelay);
		}

	}
