
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class ChapterImage extends Vue {

		@Prop({type: String, required: true})
		public src!: string;

		@Prop({type: String, default: null})
		public alt!: string | null;

		@Prop({type: String, default: undefined})
		public focalPoint!: string;

		@Prop({type: Boolean, default: false})
		public flat!: boolean;

		@Prop({type: Boolean, default: true})
		public reveal!: boolean;

		private revealed: boolean = false;

		private get containerStyles(): object {
			return {
				'background-image': `url(${this.src})`,
				'background-size': 'cover',
			};
		}

		private intersected(entries: any[]): void {
			const isIntersecting = entries[0].isIntersecting;
			if (!this.reveal || !isIntersecting || this.revealed) return;
			this.revealed = true;
		}

	}
