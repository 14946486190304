import Vue from 'vue';
import Vuetify from 'vuetify';
import {IconConfig} from '@/plugins/icons';
import colors from 'vuetify/lib/util/colors';
import i18n from "@/plugins/i18n";

Vue.use(Vuetify);

const colorPrimary: string = '#33C5C9';
const colorSecondary: string = '#555';

export default new Vuetify({
	lang: {
		t: (key, ...params) => i18n.t(key, params).toString()
	},
	icons: {
		iconfont: 'faSvg',
		values: IconConfig
	},
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				//vuetify
				primary: colorPrimary,
				secondary: colorSecondary,
				accent: colorPrimary,
				error: colors.red.lighten1,
				info: colors.blue.base,
				success: colors.green.base,
				warning: colors.orange.base,
				anchor: colorPrimary,

				//common
				default: colors.grey.base,

				//cockpit
				planning: colors.grey.base,
				backlog: colors.brown.base,
				progress: colors.blue.base,
				test: colors.purple.base,
				done: colors.green.base,

				worker: colors.blue.base,
				tester: colors.purple.base,
				subscriber: colorSecondary,
			},
		}
	},
});
