
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Chapter from "@/components/content/chapter/Chapter.vue";
	import ChapterBullets from "@/components/content/chapter/ChapterBullets.vue";
	import ChapterContent from "@/components/content/chapter/ChapterContent.vue";
	import ChapterImage from "@/components/content/chapter/ChapterImage.vue";
	import Calculator from "@/components/content/calculator/Calculator.vue";
	import Testimonials from "@/components/content/testimonials/Testimonials.vue";
	import AnnioLogoClaim from "@/components/AnnioLogoClaim.vue";

	@Component({
		components: {
			AnnioLogoClaim,
			Testimonials, Calculator, ChapterImage, ChapterContent, ChapterBullets, Chapter, AsiBtn
		}
	})
	export default class HomeView extends Vue {

		private icons = Icon;
		private activeModule = 0;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private get headerWrapperStyles(): object {
			if (this.isMobile) return {};
			return {
				'height': `calc(100vh - ${this.$vuetify.application.top}px)`,
			};
		}

		private get bannerContainerStyles(): object {
			return {
				'position': 'relative',
				'max-height': '75vh',
				'clip-path': 'polygon(0 0, 100% 0, 100% calc(100% - 3vw), 50% 100%, 0 calc(100% - 3vw))',
			};
		}

		private downloadBrochure(): void {
			window.open('/brochure/230817_annio_brochure.pdf', '_blank');
		}

		private requestDemo(): void {
			window.location.href = this.createMailtoLink('hello@annio.ch', this.$t('email.subjectDemo').toString());
		}

		private requestTestAccount(): void {
			window.location.href = this.createMailtoLink('hello@annio.ch', this.$t('email.subjectTestAccount').toString());
		}

		private navigateToYoutubeAccount() :void {
			window.open('https://www.youtube.com/@annio-collaborate-smarter');
		}

		private createMailtoLink(email: string, subject: string = ''): string {
			if (subject.length > 0) subject = `?subject=${subject}`;
			return `mailto:${email}${subject}`;
		}

	}
