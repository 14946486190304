
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import Logo from "@/components/annio/Logo.vue";
	import AnnioLogo from "@/components/AnnioLogo.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {TranslateResult} from "vue-i18n";
	import AnnioLogoClaim from "@/components/AnnioLogoClaim.vue";

	interface IMenuEntry {
		title: string | TranslateResult,
		alternativeLabel: string | TranslateResult | null,
	}

	@Component({
		components: {AnnioLogoClaim, AsiBtn, AnnioLogo, Logo}
	})
	export default class AnnioTopBar extends Vue {

		@Prop({type: Boolean, default: false})
		public menuOpen!: boolean;

		private icons = Icon;
		private attached: boolean = true;
		private attachedLimit: number = 0;

		private get navItems(): IMenuEntry[] {
			return [
				{
					title: this.$t('chapter.annio.title'),
					alternativeLabel: null,
				},
				{
					title: this.$t('terms.modules'),
					alternativeLabel: null,
				},
				{
					title: this.$t('chapter.externalAccess.title'),
					alternativeLabel: null,
				},
				{
					title: this.$t('chapter.pricing.title'),
					alternativeLabel: null,
				},
			];
		}

		public mounted(): void {
			window.addEventListener('scroll', this.scrolled);
			window.addEventListener('resize', this.resized);
			this.resized();
		}

		public beforeDestroy(): void {
			window.removeEventListener('scroll', this.scrolled);
			window.removeEventListener('resize', this.resized);
		}

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

		private get mobileMenuOpen(): boolean {
			return this.$store.state.ui.mobileMenuOpen;
		}

		private set mobileMenuOpen(isOpen: boolean) {
			this.$store.commit('ui/setMobileMenuOpen', isOpen);
		}

		private get bannerContainerHeight(): number {
			return document.getElementById('banner-container')?.offsetHeight ?? 0;
		}

		private get topBarHeight(): number {
			return document.getElementById('top-bar')?.offsetHeight ?? 0;
		}

		private scrolled(): void {
			this.attached = window.scrollY <= this.attachedLimit;
		}

		private resized(): void {
			this.attachedLimit = this.bannerContainerHeight + this.topBarHeight;
			this.scrolled();
		}

		private delayedScrollTo(title: TranslateResult | string): void {
			this.mobileMenuOpen = false;
			setTimeout(() => this.scrollTo(title), 300);
		}

		@Emit('scrollTo')
		public scrollTo(title: TranslateResult | string): TranslateResult | string {
			return title;
		}

		private delayedScrollTop(): void {
			this.mobileMenuOpen = false;
			setTimeout(() => this.scrollTop(), 300);
		}

		@Emit('scrollTop')
		public scrollTop(): void {
			return;
		}

		@Watch('isMobile')
		private onIsMobileChanged(value: boolean): void {
			if (!value) this.mobileMenuOpen = false;
		}

	}
