import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import ui, {UI} from "@/store/modules/ui";

Vue.use(Vuex);

export interface State {
	ui: UI;
}

const store = new Vuex.Store<State>({
	mutations: {},
	actions: {},
	modules: {
		ui: ui,
	},
	plugins: [
		createPersistedState({
			storage: window.localStorage,
			key: 'website-annio-store',
			reducer: (persistedState: State) => {
				const stateFilter = JSON.parse(JSON.stringify(persistedState)) as State;

				// @ts-ignore
				delete stateFilter.ui;

				return stateFilter;
			}
		}),
	],
});

export default store;
