import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=1714a5ce&scoped=true&"
import script from "./Testimonials.vue?vue&type=script&lang=ts&"
export * from "./Testimonials.vue?vue&type=script&lang=ts&"
import style0 from "./Testimonials.vue?vue&type=style&index=0&id=1714a5ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1714a5ce",
  null
  
)

export default component.exports