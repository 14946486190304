
	import Vue from 'vue';
	import Component from "vue-class-component";
	import AnnioFooter from "@/components/layout/AnnioFooter.vue";
	import AnnioTopBar from "@/components/layout/AnnioTopBar.vue";
	import {TranslateResult} from "vue-i18n";
	import StringHelper from "@/helpers/StringHelper";
	import {Watch} from "vue-property-decorator";

	@Component({
		components: {AnnioTopBar, AnnioFooter}
	})
	export default class App extends Vue {

		private get mobileMenuOpen(): boolean {
			return this.$store.state.ui.mobileMenuOpen;
		}

		@Watch('mobileMenuOpen')
		private onMobileMenuOpenChanged(value: boolean): void {
			const htmlTags = document.getElementsByTagName('html');
			const html = htmlTags.item(0) ?? null;
			if (html === null) return;
		    if (value) {
				html.classList.add('no-scroll');
		    } else {
				html.classList.remove('no-scroll');
		    }
		}

		private scrollTo(title: TranslateResult | string): void {
			const titleSlug = StringHelper.slug(title.toString());
			const titleElement = document.getElementById(titleSlug) as HTMLElement | null;
			if (titleElement === null) return;

			window.scrollTo({
				top: titleElement.offsetTop,
				behavior: 'smooth'
			});
		}

		private scrollTop(): void {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}

	}
