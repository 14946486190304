
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class AnnioLogoClaim extends Vue {

		@Prop({type: String, default: '#33C5C9'})
		public color!: string;

		private get styles(): object {
			return {
				'fill': this.color,
			};
		}

	}
