
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import LogoSwissMadeSoftware from "@/components/annio/LogoSwissMadeSoftware.vue";
	import AnnioLogoClaim from "@/components/AnnioLogoClaim.vue";

	@Component({
		components: {AnnioLogoClaim, LogoSwissMadeSoftware}
	})
	export default class AnnioFooter extends Vue {

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

	}
