
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;

	@Component
	export default class ChapterHeadline extends Vue {

		@Prop({type: [String, Object], default: null})
		public headline!: string | TranslateResult | null;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		}

	}
