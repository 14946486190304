
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class AnnioLogo extends Vue {

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public hideTypo!: boolean;

		@Prop({type: String, default: null})
		public headColor!: string | null;

		@Prop({type: String, default: null})
		public textColor!: string | null;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		private get headColorFinal(): string {
			return this.headColor !== null
				? this.headColor
				: this.$vuetify.theme.currentTheme.primary?.toString() ?? '#33C5C9';
		}

		private get textColorFinal(): string {
			if (this.textColor !== null) return this.textColor;
			return this.dark
				? '#fff'
				: this.$vuetify.theme.currentTheme.primary?.toString() ?? '#33C5C9';
		}

		private get dimensions(): string {
			return this.hideTypo
				? '0 4 19.6 31.3'
				: '0 0 100 31.3';
		}

	}
