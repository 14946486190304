
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import AsiSwitch from "@/components/common/AsiSwitch";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import CalculatorGroupHeader from "@/components/content/calculator/CalculatorGroupHeader.vue";
	import CalculatorRow from "@/components/content/calculator/CalculatorRow.vue";

	@Component({
		components: {CalculatorRow, CalculatorGroupHeader, AsiBtn, AsiTextFieldSimple, AsiSwitch}
	})
	export default class Calculator extends Vue {

		@Prop({type: Boolean, default: false})
		public descAsHint!: boolean;

		private icons = Icon;

		private moduleTasks: number = 1;
		private moduleHelpdesk: number = 0;
		private moduleTime: number = 0;
		private usersInternal: number = 1;
		private usersInternalFormValue: number | string | null = 1;
		private usersPartner: number = 0;
		private usersPartnerFormValue: number = 0;
		private usersCustomer: number = 0;
		private usersCustomerFormValue: number = 0;

		private moduleTasksPrice: number = 59.9;
		private moduleHelpdeskPrice: number = 9.9;
		private moduleTimePrice: number = 9.9;
		private userPartnerPrice: number = 4.9;
		private userCustomerPrice: number = 0;
		private additionalTbPrice: number = 49.9;

		private get numModules(): number {
			return [this.moduleTasks, this.moduleHelpdesk, this.moduleTime]
				.filter((val: number) => val > 0)
				.length;
		}

		private get userInternalPrice(): number {
			return 9.9 + (this.numModules - 1) * 2;
		}

		private get monthlyPrice(): number {
			let total = 0;
			if (this.moduleTasks) total += this.moduleTasksPrice;
			if (this.moduleHelpdesk) total += this.moduleHelpdeskPrice;
			if (this.moduleTime) total += this.moduleTimePrice;
			total += this.usersInternal * this.userInternalPrice;
			total += this.usersPartner * this.userPartnerPrice;
			return total;
		}

		private usersInternalChanged(value: number | string | null): void {
			const val = this.sanitizeNumberInput(value);
			if (val > 0 && val !== this.usersInternal) {
				this.usersInternal = val;
				this.usersInternalFormValue = val;
			} else {
				this.usersInternalFormValue = this.usersInternal;
			}
		}

		private usersPartnerChanged(value: number | string | null): void {
			const val = this.sanitizeNumberInput(value);
			if (val >= 0 && val !== this.usersPartner) {
				this.usersPartner = val;
				this.usersPartnerFormValue = val;
			} else {
				this.usersPartnerFormValue = this.usersPartner;
			}
		}

		private usersCustomerChanged(value: number | string | null): void {
			const val = this.sanitizeNumberInput(value);
			if (val >= 0 && val !== this.usersCustomer) {
				this.usersCustomer = val;
				this.usersCustomerFormValue = val;
			} else {
				this.usersCustomerFormValue = this.usersCustomer;
			}
		}

		private sanitizeNumberInput(value: number | string | null): number {
			if (value === null) {
				return 0;
			} else if (typeof value === 'string') {
				value = value.trim();
				if (value.length === 0) return 0;
				const num = Number.parseInt(value);
				if (Number.isNaN(num)) return 0;
				return num;
			}

			return Math.round(value);
		}

	}
